import {
  CORE_GET_CONTACT_LIST_NAR_WITH_DEFAULT_SEARCH,
  CORE_GET_CONTACT_LIST_NAR_WITH_DETAIL_SEARCH,
  CORE_GET_ORGUNIT_ODATA,
} from "@app/core/components/common/constant";
import { ContactClassification } from "@app/core/contacts/_id/model";
import { replaceSpecialCharacter } from "@app/core/contacts/components/dialogs/contact/util";
import { Contact_PickContactNAR_Search } from "@app/core/contacts/components/dialogs/contacts-from-nar/model";
import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { FormAction } from "@app/products/crms/model";
import { IComment } from "@app/products/hm/components/action-bar/buttons/add-comment/model";
import { InvoiceFinanceViewType } from "@app/products/local-laws/finance/model";
import { DATE_FORMAT_SEND_API } from "@common/constants/common-format";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { LinkType } from "@common/constants/linkType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ReferenceType } from "@common/models/referenceType";
import { nameOfFactory } from "@common/utils/common";
import { formatDate } from "@telerik/kendo-intl";
import { cloneDeep, isEmpty, isNil, isString } from "lodash";

//#region Address
export const apiCoreAddressMapshare = () => {
  return `api/core/internal/address/mapshare`;
};
export const apiCoreAddressMapshareCandidate = (key: string) => {
  return `api/core/internal/address/mapsharecandidate/${key}`;
};
export const apiCoreAddressGetMapshareByUserId = (userfld: string) => {
  return `api/core/internal/address/mapshare/${userfld}`;
};
export const apiCoreValidationMapshare = () => {
  return `api/core/internal/address/validation/mapshare`;
};
export const apiCoreGetAddress = () => {
  return `api/core/internal/address`;
};

export const apiCoreGetAddressLookApprox = () => {
  return `api/core/internal/addresslookapprox`;
};
export const apiCoreLookupValidate = (streetName: string) => {
  return `/api/core/internal/addressvalidation/lookup?address=${streetName}`;
};

export const apiCorePostAddressValidationCandidate = (key: string) => {
  return `/api/core/internal/addressvalidationCandidate/${key}`;
};
export const apiCoreGetValidationPhysicalAddress = () => {
  return `/api/core/internal/addressvalidation/validate`;
};

export const apiCoreStateroadsGetByRoadIdNo = () => {
  return `api/core/internal/stateroads/getbyroadidno?idno=0&name=LOCAL`;
};

//Use For handle pick address common
export const apiCoreRegisteredAddress = () => {
  return `api/core/internal/setup-address`;
};
//#endregion

//#region Comment
export const apiCoreGetComment = (comment: IComment) => {
  return `api/core/internal/comment/${comment.ID}`;
};

//ODATA
export const odataGetComments = (
  recordType: RECORDTYPE,
  isWorkflow: boolean,
  keyId?: number | string
) => {
  return `odata/core/internal/commentsregister?key=${keyId}&recordType=${recordType}&$count=true&isWorkflow=${isWorkflow}&`;
};
//#endregion

//#region Documents/Mail merge/Communication
export const apiCoreDocuments = () => {
  return `api/core/internal/document`;
};
export const apiCoreattachments = (id?: number) => {
  return `api/core/internal/document/${id}/attachments`;
};
export const apiCore = (id?: number) => {
  return `api/core/internal/document/${id}`;
};
export const apiCoreDocumentsStatus = (id: number) => {
  return `api/core/internal/documents/${id}/status`;
};
export const apiCoreDocumentsAttachment = () => {
  return `api/core/internal/documents/attachment`;
};
export const apiCoreDocumentsAttachmentById = (id: number) => {
  return `api/core/internal/documents/${id}/attachment`;
};
export const apiCoreDocumentsByDocumentIds = () => {
  return `api/core/internal/documents/get-documents`;
};
export const apiCoreDocumentsWebLink = () => {
  return `api/core/internal/documents/weblink`;
};
export const apiCoreDocumentsImages = () => {
  return `api/core/internal/documents/images`;
};
export const apiCoreDocumentAttachFile = () => {
  return `api/core/internal/documents/attach-file`;
};
export const apiCoreBatchAcknowledgeEvents = () => {
  return `api/core/internal/documents/batch-acknowledge-events`;
};
export const apiCoreDocumentById = (id?: number) => {
  return `api/core/internal/mailmerge/getmailmergedocumenttemplate/${id}`;
};
export const apiCoreSaveCommunication = (recordType: RECORDTYPE) => {
  return `api/core/internal/communication/send?recordType=${recordType}`;
};
export const apiCoreSaveMailMergeDocument = (
  productType: PRODUCT_TYPE_NUMBER,
  formAction: FormAction
) => {
  return `api/core/int/mailmerge/savemailmergedocument/${productType}?formAction=${formAction}`;
};
export const apiCoreFinanceInvoiceExport = (
  isAllRecords: boolean,
  productType: ProductType
) => {
  return `api/core/internal/finance/finance-invoice-export?productType=${productType}&isAllRecords=${isAllRecords}`;
};
export const apiCoreDocumentsAddToRecordsById = () => {
  return `api/core/internal/document/add-to-records`;
};

// ODATA
export const odataCoreGetDocuments = (
  id?: number | string,
  recordType?: RECORDTYPE
) => {
  return `/odata/core/internal/documents/getdocuments(key=${id},recordType=${recordType})?$count=true&`;
};
export const odataCoreGetDocumentsView = (
  id?: number | string,
  recordType?: RECORDTYPE
) => {
  return `/odata/core/internal/documents/getdocumentsview(key=${id},recordType=${recordType})?$count=true&`;
};
//#endregion

//#region Contact
export const apiCoreGetContactsByClassification = () => {
  return `api/core/internal/getcontactsbyclassification`;
};
export const apiCoreCloneContactProperty = (contactCloneId: number) => {
  return `api/core/internal/contacts/${contactCloneId}/clone`;
};
export const apiCoreCheckIsSuperAdmin = (userID: number) => {
  return `api/core/internal/contacts/check-is-superadmin/${userID}`;
};
export const apiCoreFindDuplicateContact = () => {
  return `api/core/internal/contacts/find-duplicates`;
};
export const apiSetupMergeContacts = () => {
  return `api/core/internal/contacts/setup-merge-contacts`;
};
export const apiMergeContacts = () => {
  return `api/core/internal/contacts/merge-contacts`;
};
export const apiPickContactFromNAR = () => {
  return `api/core/internal/contacts/get-contact-by-nar`;
};
export const apiGetContactAddressBook = () => {
  return `api/core/internal/contacts/get-contact-address-book`;
};
export const apiGetABNDetails = () => {
  return `api/core/internal/contacts/get-abn-details`;
};
export const apiCoreGetContacts = () => {
  return `api/core/internal/getcontacts/`;
};
export const apiCoreAdditionalContacts = (id: number) => {
  return `api/core/internal/contact-relationships/additional-contacts/${id}`;
};
export const apiCoreContactRoleCore = (contactRoleId: number | null) => {
  return `api/core/internal/contact/contactroles?contactId=${contactRoleId}`;
};

//ODATA
export const odataCoreGetContacts = (
  id?: number | string,
  recordType?: RECORDTYPE
) => {
  return `/odata/core/internal/contacts/getcontacts(key=${id},recordType=${recordType})?$count=true&`;
};
export const odataCoreGetContactListNARWithDetailSearch = (
  searchFields: Contact_PickContactNAR_Search
) => {
  const nameOf = nameOfFactory<Contact_PickContactNAR_Search>();
  // Removing blank search item
  Object.entries(searchFields).forEach((searchField: [string, any]) => {
    if (
      !searchField[1] ||
      (isString(searchField[1]) && searchField[1]?.trim() === "")
    ) {
      delete searchFields[
        searchField[0] as keyof Contact_PickContactNAR_Search
      ];
    }
  });

  // Formatting date
  let searchFieldsObj: Contact_PickContactNAR_Search = {};
  if (searchFields.DateOfBirth) {
    searchFieldsObj = cloneDeep({
      ...searchFields,
      [nameOf("DateOfBirth")]: formatDate(
        searchFields.DateOfBirth,
        DATE_FORMAT_SEND_API.DATE
      ),
    });
  } else {
    searchFieldsObj = cloneDeep(searchFields);
  }

  // Generating search string
  const searchFieldStr = Object.entries(searchFieldsObj)?.reduce(
    (searchFieldStr: string, searchField: string[], currentIndex: number) => {
      if (searchField?.[1]) {
        if (currentIndex === 0) {
          return (searchFieldStr += `${searchField.join("|")}`);
        } else {
          return (searchFieldStr += `|${searchField.join("|")}`);
        }
      }
      return searchFieldStr;
    },
    ""
  );

  return `${CORE_GET_CONTACT_LIST_NAR_WITH_DETAIL_SEARCH}(${
    searchFieldStr
      ? `searchFields='${replaceSpecialCharacter(searchFieldStr)}'`
      : ""
  })?$count=true&`;
};
export const odataCoreGetContactListNARWithDefaultSearch = (
  searchText: string
) => {
  return `${CORE_GET_CONTACT_LIST_NAR_WITH_DEFAULT_SEARCH}(${
    searchText ? `searchText='${searchText}'` : ""
  })?$count=true&`;
};
export const odataGetCoreContactsByClassification = (
  classification: ContactClassification
) => {
  return `odata/core/internal/contacts/GetContactsByClassification(classification=${classification})?$count=true&`;
};
//#endregion

//#region SiteUser
export const apiCoreGetLightweightSiteUser = (id?: number) => {
  return `api/core/internal/siteuser/lightweight-siteuser/${id}`;
};
export const apiCoreGetSiteUsers = () => {
  return `api/core/internal/siteuser`;
};

export const getSiteUsersByOrgStructure = (adhocGroup: boolean) => {
  return `odata/core/internal/contacts/GetSiteUsersByOrgStructure(adhocGroup=${adhocGroup})?$count=true&`;
};
//#endregion

//#region Settings
export const apiCoreSettingOptions = () => {
  return `api/core/internal/settings/CommunityProperty/Options`;
};
export const apiCoreSettings = () => {
  return `api/core/internal/settings`;
};
export const apiCoreAsseticVersion = (siteCode: string) => {
  return `api/core/internal/settings/assetic-version/${siteCode}`;
};
//#endregion

//#region Action/ActionType
export const apiCoreGetActiontypesByProductTypes = () => {
  return `api/core/internal/actions/actiontypes?productTypes=120`;
};
export const apiCoreGetActiontypes = () => {
  return `api/core/internal/actions/actiontypes`;
};
//#endregion

//#region Action Batch Update
export const apiCorePostActionBatchUpdate = () => {
  return `api/core/internal/action/batch-update`;
};
//#endregion

//#region Finance/Invoices/Fees
export const apiGetBalancesOutstanding = (
  sapId: string | undefined,
  includeDetails: boolean = false
) => {
  return `api/core/int/finance/integrations/balances/outstanding?debtorNumbers=${sapId?.toString()}&includeDetails=${includeDetails}`;
};

export const apiCoreGetFinanceInvoice = (invoiceId: number) => {
  return `/api/core/internal/finance/invoice/${invoiceId}`;
};
export const apiCoreSaveFinanceInvoice = () => {
  return `/api/core/internal/finance/invoice`;
};
export const apiCoreGetInvoiceItemColumnConfig = (
  recordType: RECORDTYPE,
  viewEnum: number
) => {
  return `/api/core/internal/common/view-configuration?recordType=${recordType}&viewEnum=${viewEnum}`;
};
export const apiCoreGetInvoiceItemById = (invoiceItemId: number) => {
  return `api/core/internal/finance/fee/${invoiceItemId}`;
};
export const apiCorePickFeeType = (feeTypeID: number) => {
  return `api/core/internal/finance/fee-type/${feeTypeID}`;
};

export const apiCoreGetFeeTypesByProductTypes = (
  productType: number | undefined = PRODUCT_TYPE_NUMBER.TownPlanning
) => {
  return `api/core/internal/fees/feetypes?ProductType=${productType}`;
};
export const apiCoreGetFeeTypesById = (id?: number) => {
  return `api/core/internal/fees/feetype/${id}`;
};
export const apiCorePostFeeTypesImport = () => {
  return `api/core/internal/feetype/import`;
};

export const apiCorePostFeeTypesExport = () => {
  return `api/core/internal/feetype/export`;
};

export const apiCoreGetFinanceFee = (
  id: number,
  parentID?: string,
  amount?: string
) => {
  let urlParams = "";
  if (parentID) {
    urlParams += `?parentID=${parentID}`;
  }

  if (amount) {
    urlParams += parentID !== null ? `&amount=${amount}` : `?amount=${amount}`;
  }

  return `api/core/internal/finance/fee/${id}${urlParams}`;
};

export const apiCoreSaveFinanceFee = () => {
  return `api/core/internal/finance/fee`;
};

//ODATA
export const odataCoreGetInvoiceItem = (
  invoiceId: number | string,
  recordType: RECORDTYPE
) => {
  return `odata/core/internal/fees/GetFees(key=${invoiceId},recordType=${recordType})?$count=true&`;
};

export const odataCoreGetInvoices = (
  id: number | string,
  recordType: RECORDTYPE
) => {
  return `/odata/core/internal/invoice/GetInvoiceAccordion(id=${id},recordType=${recordType})?$count=true&`;
};

export const odataCoreGetFinanceTransaction = (
  id: number | string,
  recordType: RECORDTYPE,
  debtorNo: string
) => {
  let debtorNoParam = "";
  if (!isEmpty(debtorNo)) {
    debtorNoParam += `,debtorNo=${debtorNo}`;
  }
  return `/odata/core/internal/financetransaction/GetFinanceTransactionsAccordion(id=${id},recordType=${recordType}${debtorNoParam})?$count=true&`;
};
//#endregion

//#region Fee Type/ Bond Type
//API
export const apiCoreGetFeeTypeLovs = () => {
  return `api/core/internal/feetype/lovs`;
};
export const apiCoreGetFeeType = (id: number) => {
  return `api/core/internal/feetype/${id}`;
};
export const apiCoreSaveFeeType = () => {
  return `api/core/internal/feetype`;
};
//OData
export const odataCoreGetFeeTypes = (
  councilID?: number,
  productType?: PRODUCT_TYPE_NUMBER
) => {
  let params = "";
  if (!isNil(councilID)) {
    params += `councilID=${councilID}`;
  }
  if (!isNil(productType)) {
    if (!isNil(councilID)) {
      params += `,productType=${productType}`;
    } else {
      params += `productType=${productType}`;
    }
  }
  return `/odata/core/internal/feetype/GetFeeTypeView(${params})?$count=true&`;
};

export const odataCoreGetBondTypes = () => {
  return `/odata/core/internal/fees/PickBondType()`;
};
//#endregion

//#region LocationRegister
export const apiCoreLocationRegisterById = (id: number) => {
  return `api/core/internal/location-register/${id}`;
};
export const apiCoreLocationRegisterLovs = () => {
  return `api/core/internal/location-register/lovs`;
};
export const apiCoreLocationRegister = () => {
  return `api/core/internal/location-register`;
};
export const apiCoreLocationRegisterHandler = () => {
  return `api/core/internal/location-register/form-handler`;
};
export const apiCoreLocationRegisterSetupPickAddress = () => {
  return `api/core/internal/location-register/setup-pick-address`;
};
export const odataCoreListMultiTenancy = () => {
  return "odata/core/internal/locationregister/GetListMultiTenancy?$count=true&";
};
//#endregion

//#region Inspection/InspectionType
export const apiCoreGetInspection = (InspectionId: number) => {
  return `api/core/internal/inspection/${InspectionId}`;
};
export const apiCoreGetInspectionLOVs = (inspectionType: number) => {
  return `api/core/internal/inspectionlovs/${inspectionType}`;
};
export const apiCoreSaveInspection = () => {
  return `api/core/internal/inspection`;
};

export const apiCoreGetInspectionTypeLovs = () => {
  return `api/core/internal/inspectiontype/lovs`;
};
export const apiCoreGetInspectionType = (id: number) => {
  return `api/core/internal/inspectiontype/${id}`;
};
export const apiCoreSaveInspectionType = () => {
  return `api/core/internal/inspectiontype`;
};

//ODATA
export const odataCoreGetInspectionTypesByProductType = (
  productType: PRODUCT_TYPE_NUMBER
) => {
  return `/odata/core/internal/inspectiontypes/GetInspectionTypesByProductType(productType=${productType})?$count=true&`;
};
export const odataCoreGetInspection = (
  id: number | string,
  recordType: RECORDTYPE
) => {
  return `/odata/core/internal/inspections/getinspections(key=${id},recordType=${recordType})?$count=true&`;
};
//#endregion

//#region Notice/NoticeType
export const apiCoreGetNotice = (noticeID: number) => {
  return `api/core/internal/notice/${noticeID}`;
};
export const apiCoreGetNoticesLOVs = () => {
  return `api/core/internal/notice/lovs`;
};
export const apiCoreSaveNotice = () => {
  return `api/core/internal/notice`;
};

export const apiCoreGetNoticeTypesById = (id?: number) => {
  return `api/core/internal/noticetype/${id}`;
};
export const apiCorePostNoticeTypes = () => {
  return `api/core/internal/noticetype`;
};
export const apiCoreGetNoticeTypesLovs = () => {
  return `api/core/internal/noticetype/lovs`;
};

//ODATA
export const odataCoreGetNoticeTypesByProductType = (
  productType: PRODUCT_TYPE_NUMBER
) => {
  return `/odata/core/internal/noticetypes/GetNoticeTypesByProductType(productType=${productType})?$count=true&`;
};
export const odataLookupNoticeTypesView = (
  productType: PRODUCT_TYPE_NUMBER
) => {
  return `/odata/core/internal/noticetypes/GetNoticeTypesByProductType(productType=${productType})?$count=true&`;
};
//#endregion

//#region Refusage
export const apiCoreRelatedRecords = () => {
  return `api/core/internal/refusage/relatedrecords`;
};
export const apiCoreRefusageRegisterType = (id: number) => {
  return `api/core/internal/refusage/register/${id}/type`;
};
export const apiCoreRefusageViewOnMap = () => {
  return `api/core/internal/refusage/view-on-map`;
};
export const apiCoreRefusageViewOnMapInView = () => {
  return `api/core/internal/refusage/view-on-map-in-view`;
};

//ODATA
export const odataGetRelatedRecordsWithRecordType = (
  sourceRecordType: RECORDTYPE
) => {
  return `/odata/core/internal/refusage/GetRelatedRecords(sourceRecordType=${sourceRecordType})?$count=true&`;
};
export const odataGetDebtorNumbers = (
  contactId?: number,
  recordId?: number,
  recordType?: RECORDTYPE
) => {
  return `/odata/core/internal/refusage/GetListDebtorNo(contactId=${contactId},recordId=${recordId},recordType=${recordType})?$count=true&`;
};
//#endregion

//#region Lookup/Keyword
export const apiGetPublicHolidayById = (id: number) => {
  return `api/core/internal/lookup/public-holiday/${id}`;
};
export const apiSavePublicHoliday = () => {
  return `api/core/internal/lookup/public-holiday`;
};
export const apiCoreGetActiontypesLovs = () => {
  return `api/core/internal/lookup/action-types-lovs`;
};

export const apiCoreGetKeywordById = (id?: number) => {
  return `api/core/internal/keywordbyid/${id}`;
};

export const apiCoreCalculateTargetDate = () => {
  return `api/core/internal/actions/calculate-target-date`;
};

//ODATA
export const odataCoreGetKeywordsByType = (
  keywordType: KEYWORD_TYPE,
  productType: PRODUCT_TYPE_NUMBER
) => {
  return `/odata/core/internal/cckeyword/GetKeywordsByType(keywordType=${keywordType},productType=${productType})`;
};
export const odataCoreGetPublicHolidays = () => {
  return `/odata/core/internal/publicholiday/GetPBHolidays?$count=true&`;
};
export const odataCoreGetPublicHolidaysByYear = (year: number) => {
  return `/odata/core/internal/publicholiday/GetPBHolidaysByYear(year=${year})?$count=true&`;
};
//#endregion

//#region Homepage/Dashboard
export const apiCoreSearch = () => {
  return `api/core/internal/dashboard/search`;
};
export const apiCoreHomePageAddUser = () => {
  return "api/core/internal/homepage/add-user";
};
export const apiCoreHomePageRemoveUser = () => {
  return "api/core/internal/homepage/remove-user";
};
export const apiCoreGetHomepageLovs = () => {
  return `api/core/internal/homepage/get-homepage/lovs`;
};
export const apiCoreGetHomepageViewsById = (homepageId: number) => {
  return `api/core/internal/homepage/get-homepage-view/${homepageId}`;
};
export const apiCoreSaveHomepageViews = () => {
  return `api/core/internal/homepage/save-homepage-view`;
};
export const apiCoreGetHomepageShortcutsById = (homepageId: number) => {
  return `api/core/internal/homepage/get-homepage-function/${homepageId}`;
};
export const apiCoreSaveHomepageShortcuts = () => {
  return `api/core/internal/homepage/save-homepage-function`;
};

//ODATA
export const odataCoreGetViewCurrentSiteUsersByHomepage = (
  functionId: number
) => {
  return `/odata/core/internal/homepageuser/GetViewCurrentSiteUsersByHomepageFunctionID(functionId=${functionId})?$count=true&`;
};

export const odataCoreGetSiteUsersByHomepageViewID = (id: string) => {
  return `odata/core/internal/homepageuser/GetViewCurrentSiteUsersByHomepageViewID(viewId=${id})?$count=true&`;
};
//#endregion

//#region DynamicQuestions/CheckList
export const apiCoreGetDynamicQuestionListsByID = (
  dynamicQuestionListsId: number
) => {
  return `api/core/internal/dynamicquestions/${dynamicQuestionListsId}`;
};
export const apiCoreGetDynamicQuestionListsLovs = () => {
  return `api/core/internal/dynamicquestions/lovs`;
};
export const apiCoreSaveDynamicQuestionLists = () => {
  return `api/core/internal/dynamicquestions`;
};
export const apiCoreDeleteDynamicQuestionLists = () => {
  return `api/core/internal/dynamicquestions/delete`;
};
export const apiCoreDeleteCheckListQuestion = () => {
  return `api/core/internal/dynamicquestions/checklist-question-detail/remove`;
};
export const apiCoreUpdateCheckListQuestion = () => {
  return `api/core/internal/dynamicquestions/checklist-question-detail/save`;
};
export const apiCoreGetDynamicQuestion = () => {
  return `api/core/internal/dynamicquestionresponses`;
};
export const apiCoreSaveDynamicQuestion = () => {
  return `api/core/internal/dynamicquestionresponses/save`;
};
export const apiCoreGetCheckListQuestionLovs = (
  dynamicQuestionId: number,
  checkListQuestionId: number
) => {
  return `api/core/internal/checklistquestion/${dynamicQuestionId}/${checkListQuestionId}/lovs`;
};

export const apiCoreSaveCheckListQuestion = () => {
  return `api/core/internal/checklistquestion`;
};
export const apiCoreGetCheckListQuestion = (checkListQuestionId: number) => {
  return `api/core/internal/checklistquestion/${checkListQuestionId}`;
};

// #region JobsScheduling
export const apiCoreCreateScheduledJob = () => {
  return `api/core/internal/create-schedule-job`;
};
export const apiCoreDeleteScheduledJobs = () => {
  return `api/core/internal/delete-schedule-jobs`;
};
export const apiCoreScheduleJobLovs = () => {
  return `api/core/internal/schedule-job-lovs`;
};
export const apiCoreGetScheduleJob = () => {
  return `api/core/internal/get-schedule-job`;
};
export const apiCoreUpdateScheduledJob = () => {
  return `api/core/internal/update-schedule-job`;
};
// #endregion JobsScheduling

//ODATA
export const odataCoreGetDynamicQuestionListView = () => {
  return "odata/core/internal/dynamicquestion/GetDynamicQuestionListView?$count=true&";
};
//#endregion

//#region CoreLink
export const apiCoreCorelinkChangeStatus = () => {
  return `api/core/internal/corelink/changestatus`;
};
export const apiCoreLinkList = (
  id: number,
  recordType: RECORDTYPE,
  linkType: LinkType
) => {
  return `api/core/internal/core/${id}/linklist?recordType=${recordType}&linkType=${linkType}`;
};
//#endregion

//#region Sample
// ODATA
export const odataCoreGetSamples = (
  id?: number | string,
  recordType?: RECORDTYPE
) => {
  return `/odata/core/internal/samples/getsamples(key=${id},recordType=${recordType})?$count=true&`;
};
//#endregion

//#region OrgUnit
// ODATA
export const odataCoreGetOrgUnit = (
  isAdhoc: boolean,
  orgLevel: number | string | null
) => {
  return `/${CORE_GET_ORGUNIT_ODATA}(isAdhoc=${isAdhoc},orgLevel=${orgLevel})?$count=true&`;
};
//#endregion

//#region Events
// ODATA
export const odataGetEventsAccordion = (
  id: number | string,
  recordType: RECORDTYPE
) => {
  return `/odata/crms/internal/events/GetCUSTRequestsAccordion(recordType=${recordType},id=${id})?$count=true&`;
};
//#endregion

//#region Alert
export const apiCoreAlert = (id: number, recordType: RECORDTYPE) => {
  return `api/core/int/alert/${id}/${recordType}`;
};

export const apiCoreGetAlert = (id: number) => {
  return `api/core/int/alert/${id}`;
};

export const apiCoreGetAlertLovs = () => {
  return `api/core/int/alert/lovs`;
};
//ODATA
export const odataCoreAlertsAccordion = (
  key: number | null,
  recordType: RECORDTYPE
) => {
  return `odata/core/internal/alertsregister?key=${key}&recordType=${recordType}&$count=true&`;
};
//#endregion

export const odataCoreGetConditionReferences = (
  productType: PRODUCT_TYPE_NUMBER,
  referenceType?: ReferenceType
) => {
  let params = "";
  if (!isNil(referenceType)) {
    params = `,referenceType=${referenceType}`;
  }
  return `odata/core/internal/conditionreferences/GetConditionReferencesView(productType=${productType}${params})?$count=true&`;
};

export const odataCoreGetInvoiceToFinance = (
  productType: PRODUCT_TYPE_NUMBER,
  viewType: InvoiceFinanceViewType
) => {
  return `odata/core/internal/invoice/GetInvoiceToFinance(productType=${productType},viewType=${viewType})?$count=true&`;
};

export const odataCoreGetAttachmentRecordsViews = (noFileNumber: boolean) => {
  return `odata/core/internal/records/GetViewAttachmentRecords(noFileNumber=${noFileNumber})?$count=true&`;
};

//#region Condition
export const apiCoreAddConditions = () => `api/core/internal/conditions/add`;
export const apiCoreGetCondition = (conditionId: number) => {
  return `api/core/internal/condition/${conditionId}`;
};
export const apiCoreGetConditionLOVs = () => {
  return `api/core/internal/conditionlovs`;
};
export const apiCoreSaveCondition = () => {
  return `api/core/internal/condition`;
};
export const apiCoreUpdateConditionSortIndex = (
  condition_ID: number,
  sortIndex: number
) => {
  return `api/core/internal/condition/updatesort?condition_ID=${condition_ID}&sortIndex=${sortIndex}`;
};

//#region Condition Reference ========/
export const apiCoreGetConditionReference = (conditionId: number) => {
  return `api/core/internal/condition-reference/${conditionId}`;
};

export const apiCoreAddConditionsReference = () =>
  `api/core/internal/conditions-reference/add`;

export const apiCoreGetConditionReferenceLOVs = () => {
  return `api/core/internal/condition-reference/lovs`;
};

export const apiCoreSaveConditionReference = () => {
  return `api/core/internal/condition-reference`;
};
//#endregion Condition Reference =====/

//#region ODATA
export const odataCoreGetConditions = (
  recordSource_Id: number | string,
  recordType: RECORDTYPE
) => {
  return `/odata/core/internal/conditions/GetConditionAccordion(recordSource_Id=${recordSource_Id},recordType=${recordType})?$count=true&`;
};
//#endregion
//#endregion
