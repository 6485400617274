import { Svc_ViewOnMap } from "@app/core/view-on-map/components/buttons/view-map-by-gis/model";
import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { SavePremisesButton } from "@app/products/hm/premises/[id]/components/buttons/save/_index";
import { PremiseForm } from "@app/products/hm/premises/[id]/components/child-screens/general/_index";
import { AccordionsHmPremises } from "@app/products/hm/premises/[id]/components/child-screens/general/components/premises-accordions/_index";
import { PremiseContactsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/contacts/_index";
import { PremiseDetailsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/details/_index";
import { PremiseHistoryTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/history/_index";
import {
  Premises,
  Premises_Status,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { checkIsInactivePremises } from "@app/products/hm/premises/[id]/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

export const ExistHMManagePremise = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const { checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.HealthManager_Form_Premises,
    productType: PRODUCT_TYPE_NUMBER.HealthManager,
  });
  const allowSaveAndEdit = checkPermissions(FormAction.CORE_ALLOW_EDIT);

  const isInactive = checkIsInactivePremises();

  const [isShowSlideBar, setIsShowSlideBar] = useState(true);
  const { currentUserInfo } = useGlobalStore();
  const managePageUrl = window.location.pathname;
  const premiseObj = dataForms?.GeneralForm as Svc_Premises;
  const premiseData = premiseObj?.Premises as Premises;

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: "Health Manager - Premises",
      LinkUrl: managePageUrl,
      LinkText: `Heath Manager - Premises - ${premiseData?.Premises_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.HealthManager,
      RecordType_ENUM: RECORDTYPE.HealthManager_Premises,
      Record_ID: premiseData?.Premises_ID ? +premiseData?.Premises_ID : 0,
    },
  ];

  const generateBadge = (initStatus?: string) => {
    const listBadge = initStatus ? [initStatus] : [];
    if (premiseData?.Status_ENUM) {
      listBadge.push(Premises_Status[premiseData.Status_ENUM]);
    }
    if (premiseData?.Alert) {
      listBadge.push("Alert");
    }
    return listBadge;
  };

  const title = useMemo(() => {
    let result = premiseData?.PremisesType?.PremisesClassification?.Name ?? "";
    if (premiseData?.RegistrationNumber) {
      result += ` - ${premiseData?.RegistrationNumber}`;
    }
    return result;
  }, [
    premiseData?.PremisesType?.PremisesClassification?.Name,
    premiseData?.RegistrationNumber,
  ]);

  return (
    <>
      <FormTitle title={title} badge={generateBadge()} />
      <CCManagePageActionBar
        leftActions={[
          <SavePremisesButton isDisabled={isInactive || !allowSaveAndEdit} />,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={isShowSlideBar}
            onClick={() => {
              setIsShowSlideBar(!isShowSlideBar);
            }}
          />,

          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />

      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <CCAppNotification />
          <div className="cc-manage-form-body">
            {premiseData && <PremiseForm />}
            <AccordionsHmPremises premiseData={premiseData} />
          </div>
        </div>

        {isShowSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                { title: "Details", component: <PremiseDetailsTab /> },
                {
                  title: "Map",
                  component: (
                    <MapTab
                      address={premiseData?.SiteAddress?.Formatted_SingleLine}
                      zoom={18}
                      objViewOnMapRequest={
                        {
                          ReturnControllName: "GISKey",
                          GISKey: premiseData?.SiteAddress?.AssessmentNo ?? "",
                          ObjAddress: premiseData?.SiteAddress,
                          Module: "",
                          Layer: "",
                          ProductType: ProductType.HealthManager,
                        } as Svc_ViewOnMap
                      }
                      isInManagePage
                    />
                  ),
                },
                { title: "Contacts", component: <PremiseContactsTab /> },
                {
                  title: "History",
                  component: <PremiseHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
