import { eventEmitter } from "@/App";
import { addConditions } from "@app/core/condition/components/buttons/add-standard-condition/api";
import { ListConditionReferenceDialog } from "@app/core/condition/components/dialogs/list-condition-references/_index";
import { YieldNotificationPortal_CONDITION_REFERENCE_DIALOG } from "@app/core/condition/components/dialogs/list-condition-references/config";
import { ConditionReferenceGroupSetting } from "@app/core/condition/components/dialogs/list-condition-references/constant";
import {
  Svc_ConditionRecord,
  Svc_ConditionReference,
} from "@app/core/condition/components/dialogs/list-condition-references/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import React, { useState } from "react";

export const AddStdConditionButton = ({
  productType,
  recordType,
  recordID,
  isDisabled = false,
}: {
  productType: keyof typeof ConditionReferenceGroupSetting;
  recordType: RECORDTYPE;
  recordID: number;
  isDisabled?: boolean;
}) => {
  const [isShowingDialog, setIsShowingDialog] = useState(false);
  const [isCallingAPI, setIsCallingAPI] = useState(false);
  const { pushNotificationPortal } = useNotificationPortalStore();

  const addConditionsSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }: { initialData: Svc_ConditionReference[] }) => {
          setIsCallingAPI(true);
          return addConditions(
            initialData.map(
              (conditionReference): Svc_ConditionRecord => ({
                ConditionReference_ID: conditionReference.ID,
                Source_RecordType_ENUM: recordType,
                Source_RecordType_Int: recordType,
                Source_Record_ID: recordID,
              })
            )
          );
        },
        handleSuccess: () => {
          handleClose();
          eventEmitter.emit(TabTableEventType.RefreshData);
          eventEmitter.emit(CCJournalEventType.RefreshData);
          eventEmitter.emit(CCGridEventType.RefreshOData);
          pushNotificationPortal({
            type: "success",
            title: "Add std condition successfully",
          });
        },
        handleError: ({ errorFromApi }) => {
          setIsCallingAPI(false);
          pushNotificationPortal({
            placeId: YieldNotificationPortal_CONDITION_REFERENCE_DIALOG,
            type: "error",
            title: "Add std condition failed",
            description: errorFromApi?.error,
            autoClose: false,
          });
        },
      },
    ],
  });

  const handleSubmit = (data: Svc_ConditionReference[]) => {
    if (data.length === 0) return;
    addConditionsSlider.fetchApi({ initialData: data });
  };

  const handleClose = () => {
    setIsShowingDialog(false);
    setIsCallingAPI(false);
  };

  return (
    <>
      <CCNavButton
        title="Std Condition"
        onClick={() => setIsShowingDialog(true)}
        disabled={isDisabled}
      />
      {isShowingDialog ? (
        <ListConditionReferenceDialog
          productType={productType}
          onClosePopup={handleClose}
          onSubmit={handleSubmit}
          isLoading={isCallingAPI}
        />
      ) : null}
    </>
  );
};
