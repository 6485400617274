import { renderOptionInputPickerKeyword } from "@app/core/components/common/config";
import { getNameOfPremises } from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { DATE_FORMAT } from "@common/constants/common-format";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { Keyword } from "@common/models/keyword";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export interface IJurisdictionSectionProps {
  formRenderProps: FormRenderProps;
  isInactive?: boolean;
}

export const JurisdictionSection = observer(
  ({ formRenderProps, isInactive = false }: IJurisdictionSectionProps) => {
    const { settings } = useCommonCoreStore();
    const { valueGetter, onChange } = formRenderProps;

    // #region GET SETTING VALUES
    const enableJurisdiction = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_EnableJurisdiction]
    );
    const enableJurisdiction2 = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_EnableJurisdiction2]
    );
    // #endregion GET SETTING VALUES

    // #region SHOW/HIDE VARIABLES
    const trJurisdictionDetailsVisible = useMemo(
      () => {
        return (
          valueGetter(getNameOfPremises("PremisesType")) &&
          enableJurisdiction &&
          (valueGetter(
            `${getNameOfPremises("PremisesType")}.Flag_FoodVehicle`
          ) ||
            valueGetter(
              `${getNameOfPremises("PremisesType")}.Flag_TemporaryPremises`
            ))
        );
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(getNameOfPremises("PremisesType"))]
    );

    const trJurisdictionDetails2Visible = useMemo(
      () => {
        return (
          valueGetter(getNameOfPremises("PremisesType")) &&
          enableJurisdiction &&
          !enableJurisdiction2
        );
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(getNameOfPremises("PremisesType"))]
    );
    // #endregion SHOW/HIDE VARIABLES

    // #region Functions
    const handleSelectLGA = async (event: Keyword) => {
      if (event?.Keyword_ID) {
        onChange(getNameOfPremises("LGA_KWD"), { value: event?.Keyword_ID });
        onChange(getNameOfPremises("LGA_Name"), { value: event?.Name });
      } else {
        onChange(getNameOfPremises("LGA_KWD"), { value: null });
        onChange(getNameOfPremises("LGA_Name"), { value: null });
      }
    };
    // #endregion Functions

    return (
      (trJurisdictionDetails2Visible || trJurisdictionDetailsVisible) && (
        <>
          <hr className="cc-divider" />
          <section className="cc-field-group">
            {/* trJurisdictionDetails2 */}
            {trJurisdictionDetails2Visible && (
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Home jurisdiction" isMandatory />
                  <Field
                    name={getNameOfPremises("LGA_Name")}
                    placeholder="Select theme"
                    component={InputPickerSearch}
                    onChange={handleSelectLGA}
                    validator={requiredValidator}
                    options={renderOptionInputPickerKeyword(
                      PRODUCT_TYPE_NUMBER.Core,
                      KEYWORD_TYPE.Core_LGA,
                      "LGA"
                    )}
                    disabled={isInactive}
                  />
                </div>
              </div>
            )}

            {/* trJurisdictionDetails */}
            {trJurisdictionDetailsVisible && (
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Home jurisdiction" />
                  <Field
                    name={getNameOfPremises("LGA_Name")}
                    placeholder="Select jurisdiction"
                    component={InputPickerSearch}
                    onChange={handleSelectLGA}
                    options={renderOptionInputPickerKeyword(
                      PRODUCT_TYPE_NUMBER.Core,
                      KEYWORD_TYPE.Core_LGA,
                      "LGA"
                    )}
                    disabled={isInactive}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Copy of last inspection report" />
                  <Field
                    name={getNameOfPremises("Date_CopyofLastInspectionReport")}
                    format={DATE_FORMAT.DATE_CONTROL}
                    component={DatePicker}
                    disabled={isInactive}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Address" />
                  <Field
                    name={getNameOfPremises("AddressHomeJurisdiction")}
                    component={CCInput}
                    disabled={isInactive}
                  />
                </div>
              </div>
            )}
          </section>
        </>
      )
    );
  }
);
