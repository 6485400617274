import { managePagePropertyWrapper } from "@app/products/property/components/action-bar/property-workflow/component/hoc/manage-page-property-wrapper";
import ExistedPropertyEntity from "@app/products/property/contacts-central-names/[id]/components/forms/existed/_index";
import { NewPropertyEntity } from "@app/products/property/contacts-central-names/[id]/components/forms/new/_index";
import { useContactsCentralNamesStore } from "@app/products/property/contacts-central-names/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManagePropertyEntity = observer(() => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const contactId = params.id;
  const { resetMenu } = useCCSubActionBarStore();
  const { resetStore, loadContact } = useContactsCentralNamesStore();
  const location = useLocation();
  const state: any = location?.state;

  useEffect(() => {
    if (isNew) return;
    let notification = undefined;
    if (state) {
      notification = state?.notification;
      window.history.replaceState({}, "");
    }
    loadContact(parseInt(contactId), notification);
  }, [isNew, contactId, loadContact, state]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  if (isNew) {
    return <NewPropertyEntity />;
  }

  return <ExistedPropertyEntity />;
});

export default managePagePropertyWrapper(ManagePropertyEntity);
