import { iconColumnViewConfigAlert } from "@app/products/crms/config";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import {
  EnumPremisesColumns,
  HMPremisesRegisterView,
} from "@app/products/hm/premises/model";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { PROPERTYSYSTEM, RECORDSSYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { cloneDeep, toString } from "lodash";

export const nameOfHMPremisesRegister = nameOfFactory<HMPremisesRegisterView>();

export const colHMPremisesRegister = [
  {
    field: nameOfHMPremisesRegister("RegNo"),
    title: "Registration Number",
    locked: true,
    linkTo: (dataItem: HMPremisesRegisterView) => {
      return `${PREMISES_MANAGE_ROUTE}/` + dataItem.ID;
    },
  },
  ...iconColumnViewConfigAlert,
  {
    field: nameOfHMPremisesRegister("DebtorNo"),
    title: "Debtor Number",
  },
  {
    field: nameOfHMPremisesRegister("AssessmentNo"),
    title: "Assessment Number",
  },
  {
    field: nameOfHMPremisesRegister("FileNumber"),
    title: "File Number",
  },
  {
    field: nameOfHMPremisesRegister("TradingName"),
    title: "Trading Name",
  },
  {
    field: nameOfHMPremisesRegister("LocationDesc"),
    title: "Location Description",
  },
  {
    field: nameOfHMPremisesRegister("StreetNo"),
    title: "Number",
  },
  {
    field: nameOfHMPremisesRegister("AddressLine1"),
    title: "Address",
  },
  {
    field: nameOfHMPremisesRegister("Street"),
    title: "Street",
  },
  {
    field: nameOfHMPremisesRegister("Suburb"),
    title: "Locality",
  },
  {
    field: nameOfHMPremisesRegister("Status"),
    title: "Status",
  },
  {
    field: nameOfHMPremisesRegister("Officer"),
    title: "Officer",
  },
  {
    field: nameOfHMPremisesRegister("PremisesType"),
    title: "Type",
  },
  {
    field: nameOfHMPremisesRegister("PremisesClassification"),
    title: "Classification",
  },
  {
    field: nameOfHMPremisesRegister("Area"),
    title: "Area",
  },
  {
    field: nameOfHMPremisesRegister("Jurisdiction"),
    title: "Jurisdiction",
  },
  {
    field: nameOfHMPremisesRegister("Risk"),
    title: "Risk",
  },

  {
    field: nameOfHMPremisesRegister("AmountOS"),
    title: "Outstanding",
    format: CURRENCY_FORMAT.CURRENCY2,
  },
  {
    field: nameOfHMPremisesRegister("RefNo"),
    title: "Reference Number",
  },
  {
    field: nameOfHMPremisesRegister("Flag_MultiTenancy"),
    title: "Multi",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesRegister("Flag_DeptOfEducation"),
    title: "Dept of Education",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesRegister("HomeBusiness"),
    title: "Home Business",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOfHMPremisesRegister("LastInspection"),
    title: "Last Inspection",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesRegister("LastCompliance"),
    title: "Last Compliance",
  },
  {
    field: nameOfHMPremisesRegister("PreviousCategory"),
    title: "Previous Category",
  },
  {
    field: nameOfHMPremisesRegister("Email"),
    title: "Email",
  },
  {
    field: nameOfHMPremisesRegister("PostalAddress"),
    title: "Postal Address",
  },
  {
    field: nameOfHMPremisesRegister("PostalAddress2"),
    title: "Postal Address 2",
  },

  {
    field: nameOfHMPremisesRegister("Proprietor"),
    title: "Proprietor",
  },
  {
    field: nameOfHMPremisesRegister("Mobile"),
    title: "Mobile",
  },
  {
    field: nameOfHMPremisesRegister("Phone"),
    title: "Phone",
  },
  {
    field: nameOfHMPremisesRegister("ProprietorABN"),
    title: "ABN",
  },
  {
    field: nameOfHMPremisesRegister("ProprietorACN"),
    title: "ACN",
  },
  {
    field: nameOfHMPremisesRegister("NoEmployees"),
    title: "Employees",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfHMPremisesRegister("PrimaryContact"),
    title: "Contact",
  },
  {
    field: nameOfHMPremisesRegister("FSPContact"),
    title: "FSS Contact",
  },
  {
    field: nameOfHMPremisesRegister("FSP"),
    title: "FSP Template",
  },
  {
    field: nameOfHMPremisesRegister("DateLodged"),
    title: "Lodged",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesRegister("DateRegistrationExpires"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfHMPremisesRegister("Fax"),
    title: "Fax",
  },
];

export interface IGenerateHMPremisesRegisterColumnsBySettingProps {
  defaultConfig: IColumnFields[];
  settings?: SettingsMap;
}

export const generateHMPremisesRegisterColumnsBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateHMPremisesRegisterColumnsBySettingProps) => {
  if (!settings) return defaultConfig;

  const registrationNoLabel = getStringValueSetting(
    settings[ECorporateSettingsField.HealthManager_RegistrationNoLabel]
  );
  const debtorNumberLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
  );
  const premisesColumns = getStringValueSetting(
    settings[ECorporateSettingsField.HealthManager_PremisesColumns]
  )?.split("|");
  const isShowDebtorNumber =
    getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_RegisterDisplayDebtorNumber
      ]
    ) ?? false;
  const propertySystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_PropertySystem]
  );
  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );
  const isShowLocationDesc =
    getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_ShowLoactionDesc]
    ) ?? false;
  const isEnableJurisdiction =
    getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_EnableJurisdiction]
    ) ?? false;
  const isShowLastInspOSFees =
    getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_ShowLastInspOSFees]
    ) ?? false;
  const isMultiTenancy =
    getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_MultiTenancy]
    ) ?? false;

  let newConfig: IColumnFields[] = cloneDeep(defaultConfig);
  return newConfig.filter((col) => {
    switch (col.field) {
      case nameOfHMPremisesRegister("AmountOS"):
      case nameOfHMPremisesRegister("LastInspection"):
      case nameOfHMPremisesRegister("LastCompliance"):
        return isShowLastInspOSFees;
      case nameOfHMPremisesRegister("Flag_MultiTenancy"):
      case nameOfHMPremisesRegister("Flag_DeptOfEducation"):
      case nameOfHMPremisesRegister("PreviousCategory"):
        return isMultiTenancy;
    }

    let isVisible = true;
    // By default, columns in EnumPremisesColumns are hidden.
    if (col.field in EnumPremisesColumns) {
      isVisible = false;
    }

    if (col.field === nameOfHMPremisesRegister("FSPContact")) {
      // Check this because the field from data is different from EnumPremisesColumns
      return (
        premisesColumns?.includes(toString(EnumPremisesColumns.FSSContact)) ??
        isVisible
      );
    }

    if (col.field === nameOfHMPremisesRegister("FSP")) {
      // Check this because the field from data is different from EnumPremisesColumns
      return (
        premisesColumns?.includes(toString(EnumPremisesColumns.FSPTemplate)) ??
        isVisible
      );
    }

    const field = col.field as keyof typeof EnumPremisesColumns;
    const fieldIndex = toString(EnumPremisesColumns[field]);
    if (fieldIndex && premisesColumns?.includes(fieldIndex)) {
      isVisible = true;
    }

    switch (col.field) {
      case nameOfHMPremisesRegister("LocationDesc"):
        return isVisible && isShowLocationDesc;
      case nameOfHMPremisesRegister("Jurisdiction"):
        return isVisible && isEnableJurisdiction;
      case nameOfHMPremisesRegister("RegNo"):
        if (registrationNoLabel) {
          col.title = registrationNoLabel;
        }
        break;
      case nameOfHMPremisesRegister("DebtorNo"):
        if (debtorNumberLabel) {
          col.title = debtorNumberLabel;
        }
        if (!isShowDebtorNumber) {
          return false;
        }
        break;
      case nameOfHMPremisesRegister("AssessmentNo"):
        if (propertySystem === PROPERTYSYSTEM.None) {
          return false;
        }
        break;
      case nameOfHMPremisesRegister("FileNumber"):
        if (recordsSystem === RECORDSSYSTEM.None) {
          return false;
        }
        break;
    }

    return isVisible;
  });
};
