import { Comments } from "@app/core/comments/_index";
import Conditions from "@app/core/condition/condition-accordion/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import Inspections from "@app/core/inspections/inspections-accordion/_index";
import { InvoicesAccordion } from "@app/core/invoice/invoice-accordion/_index";
import { RelatedAccordion } from "@app/core/related/related-accordion/_index";
import { ICoreRelatedParams } from "@app/core/related/related-accordion/model";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { Premises } from "@app/products/hm/premises/[id]/model";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { DefaultTab } from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export interface IAccordionsHmPremisesProps {
  premiseData: Premises;
}

export const AccordionsHmPremises = observer(
  ({ premiseData }: IAccordionsHmPremisesProps) => {
    const { dataForms } = useFlexibleFormStore();
    const premisesObj = dataForms?.GeneralForm?.Premises as Premises;
    const idUrl: string = useParams<{ id: string }>().id;
    const id: number = premisesObj?.Premises_ID || parseInt(idUrl ?? "0");

    const { settings } = useCommonCoreStore();
    const healthManagerDefaultTab = getNumberValueSetting(
      settings[ECorporateSettingsField.HealthManager_DefaultTab]
    );
    const healthManagerSimpleConditions = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_SimpleConditions]
    );

    const relatedAccordionParams = useMemo(() => {
      if (premisesObj && id) {
        const data: ICoreRelatedParams = {
          RecordType: RECORDTYPE.HealthManager_Premises,
          RecordID: id,
          InvoiceNumber: null,
          AssessmentNumberKey: null,
          AddressLine1: null,
          Suburb: null,
          PersonKey: premisesObj?.Proprietor?.Contact?.Contact_ID,
          WasteWaterSchemeKey: null,
          AncestorRecordType: null,
          AncestorRecordId: null,
        };

        if (premisesObj?.SiteAddress) {
          data.AssessmentNumberKey =
            premisesObj?.SiteAddress?.PropertyAssessment?.PID_No;
          data.AddressLine1 = premisesObj.SiteAddress?.Formatted_AddressLine1;
          data.Suburb = premisesObj.SiteAddress?.Suburb;
        }

        return data;
      }
      return undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [premisesObj, id]);

    const listPanelBar: ITabProps[] = [
      {
        title: "Actions",
        tabType: TabTableType.Actions,
        isExpanded: healthManagerDefaultTab === DefaultTab.Actions,
        component: (
          <PPRActions id={id} recordType={RECORDTYPE.HealthManager_Premises} />
        ),
      },
      {
        title: "Comments",
        isExpanded: healthManagerDefaultTab === DefaultTab.Comments,
        tabType: TabTableType.Comments,
        component: (
          <Comments id={id} recordType={RECORDTYPE.HealthManager_Premises} />
        ),
      },
      {
        title: "Contacts",
        tabType: TabTableType.Contacts,
        isExpanded: healthManagerDefaultTab === DefaultTab.Contacts,
        component: (
          <ContactAccordion
            id={id}
            recordType={RECORDTYPE.HealthManager_Premises}
          />
        ),
      },
      {
        title: "Records",
        tabType: TabTableType.Documents,
        isExpanded: healthManagerDefaultTab === DefaultTab.Documents,
        component: (
          <Documents
            id={id}
            recordType={RECORDTYPE.HealthManager_Premises}
            isUseSharepoint
            isAddToRecords
            isExistedFolderRecords={!isEmpty(premisesObj?.FileNumber)}
          />
        ),
      },
      {
        title: "Invoices",
        tabType: TabTableType.Invoices,
        isVisible: premiseData?.PremisesType?.Flag_Fees,
        isExpanded: healthManagerDefaultTab === DefaultTab.Fees,
        component: (
          <InvoicesAccordion
            id={id}
            recordType={RECORDTYPE.HealthManager_Premises}
          />
        ),
      },
      {
        title: "Inspections",
        tabType: TabTableType.Inspections,
        isVisible: premiseData?.PremisesType?.Flag_Inspections,
        isExpanded: healthManagerDefaultTab === DefaultTab.Inspections,
        component: (
          <Inspections id={id} recordType={RECORDTYPE.HealthManager_Premises} />
        ),
      },
      {
        title: "Conditions",
        tabType: TabTableType.Conditions,
        isVisible: healthManagerSimpleConditions === false,
        component: (
          <Conditions id={id} recordType={RECORDTYPE.HealthManager_Premises} />
        ),
      },
      {
        title: "Related",
        tabType: TabTableType.Related,
        isExpanded: healthManagerDefaultTab === DefaultTab.Related,
        component: <RelatedAccordion relatedParam={relatedAccordionParams} />,
      },
    ];
    return (
      <TabTable
        id={id}
        recordType={RECORDTYPE.HealthManager_Premises}
        initialPanels={listPanelBar}
        options={{
          invoiceNumber: relatedAccordionParams?.InvoiceNumber,
          propertyAssessmentNoKey: relatedAccordionParams?.AssessmentNumberKey,
          addressLine1: relatedAccordionParams?.AddressLine1,
          suburb: relatedAccordionParams?.Suburb,
          personKey: relatedAccordionParams?.PersonKey,
          schemeKey: relatedAccordionParams?.WasteWaterSchemeKey,
          refNoKey: relatedAccordionParams?.CsmRefNoKey
            ? relatedAccordionParams.CsmRefNoKey.toString()
            : undefined,
          ancestorRecordId: relatedAccordionParams?.AncestorRecordId,
          ancestorRecordType: relatedAccordionParams?.AncestorRecordType,
        }}
      />
    );
  }
);
