import {
  getConditionById,
  getConditionLovs,
} from "@app/core/condition/[id]/api";
import {
  CONDITION_SLIDER,
  TitleConditionManagePage,
} from "@app/core/condition/[id]/constant";
import { ExistManageCondition } from "@app/core/condition/[id]/existed/_index";
import {
  IParentConditionSection,
  Svc_Condition,
  Svc_Condition_Group,
  Svc_Condition_Lovs,
} from "@app/core/condition/[id]/model";
import { NewManageCondition } from "@app/core/condition/[id]/new/_index";
import {
  BubbleUpType,
  Svc_BubbleUpIdentifier,
} from "@app/core/inspections/[id]/model";
import {
  mapEnum,
  productTypeNumberLogic,
} from "@app/core/inspections/[id]/util";
import { DBRowState } from "@app/products/crms/[id]/model";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { routeDataStoreInstance } from "@common/stores/route/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageCondition = () => {
  //#region STORE ========/
  const history = useHistory();
  const { setInitialDataForms, isLoadingForm } = useFlexibleFormStore();

  const { state } = useLocation<{
    parent?: IParentConditionSection;
  }>();
  //#endregion STORE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  const isNew = useIsNew();
  const { pushNotificationPortal, clearNotifications } =
    useNotificationPortalStore();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const conditionId = useMemo(() => {
    return isNew ? "0" : id;
  }, [isNew, id]);
  //#endregion Variable =====/

  useEffectOnce(() => {
    return () => {
      clearNotifications();
    };
  });

  const manageConditionSlider = useFlexibleFetchData({
    alias: CONDITION_SLIDER,
    slides: [
      {
        fetch: () => {
          return getConditionById(+conditionId, cancelToken());
        },
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<IIdentityPacket<Svc_Condition>> =
            dataFromApi;
          const conditionFormData = response?.data?.ReturnObj?.Condition;
          if (+conditionId === 0) {
            conditionFormData.AllowPublish = false;
          }
          if (
            mapEnum(conditionFormData.Sys_DBRowState, DBRowState) ===
              DBRowState.Inactive &&
            +conditionId !== 0
          ) {
            pushNotificationPortal({
              title: "Important Note",
              type: "info",
              description:
                "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
              autoClose: false,
            });
          }
        },
        extractParamForNextAPI: ({ dataFromApi }) => {
          const response: APIResponse<IIdentityPacket<Svc_Condition>> =
            dataFromApi;
          return response?.data?.ReturnObj;
        },
      },
      {
        fetch: ({ dataFromExtractParam }) => {
          const svcCondition: Svc_Condition = dataFromExtractParam;
          const condition = svcCondition?.Condition;
          const parentBubbleUps: Svc_BubbleUpIdentifier[] | undefined =
            condition?._BubbleUps;
          let productType: PRODUCT_TYPE_NUMBER | undefined;
          let recordType: RECORDTYPE | undefined;
          if (isNew) {
            productType = state?.parent?.productType;
            recordType = state?.parent?.recordType;
          } else {
            const parentBubbleUp = parentBubbleUps?.find(
              (item) => item.BubbleUpType_ENUM === BubbleUpType.Parent
            );
            productType = productTypeNumberLogic(
              parentBubbleUp?.SourceIdentifier?._RecordSourceType_ENUM
            );
            recordType =
              parentBubbleUp?.SourceIdentifier?._RecordSourceType_ENUM;
          }
          return getConditionLovs(
            productType ?? PRODUCT_TYPE_NUMBER.SystemInitialise,
            recordType ?? RECORDTYPE.SystemInitialise,
            cancelToken()
          );
        },
        handleSuccess: ({ dataFromApi, dataFromExtractParam }) => {
          const svcCondition: Svc_Condition = dataFromExtractParam;
          const condition = svcCondition?.Condition;
          const response: APIResponse<IIdentityPacket<Svc_Condition_Lovs>> =
            dataFromApi;
          const ddAccountLOVs = response?.data?.ReturnObj;
          const notExistGroup =
            ddAccountLOVs?.Groups.find(
              (group: Svc_Condition_Group) => group.Group === condition.Group
            ) === undefined;
          if (!isNew && condition?.Group && notExistGroup) {
            ddAccountLOVs.Groups.push({
              Group: condition.Group,
              ProductType_ENUM: null,
            });
          }
          setInitialDataForms({
            GeneralForm: {
              ...condition,
              CCFormOptions: {
                parentState: {
                  ...state?.parent,
                  isLockCategory: svcCondition.LockCategory,
                },
              },
            },
            GeneralFormLovs: ddAccountLOVs,
          });
        },
      },
    ],
  });

  useEffect(() => {
    manageConditionSlider.fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <Loading
        isLoading={
          manageConditionSlider.isFetching ||
          routeDataStoreInstance.isLoadingPage ||
          isLoadingForm
        }
        isFullScreen
      />
      <FormNavigation title={TitleConditionManagePage} />
      {manageConditionSlider.errorComponent ? (
        manageConditionSlider.errorComponent
      ) : isNew ? (
        <NewManageCondition />
      ) : (
        <ExistManageCondition />
      )}
    </>
  );
};

export default observer(ManageCondition);
