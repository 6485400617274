import { systemAdminRoute } from "@app/products/hm/system-admin/route";
import { ICCRoute } from "@common/constants/ICCRoute";
import { eProductType } from "@common/stores/products/enum";
import { complaintsRoute } from "./complaints/route";
import { financeRoute } from "./finance/route";
import { fsrRoute } from "./fsr/route";
import { govtReportsRoute } from "./govt-report/route";
import { inspScheduleRoute } from "./insp-schedule/route";
import { inspectionsRoute } from "./inspections/route";
import { noticesRoute } from "./notices/route";
import { premisesRoute } from "./premises/route";
import { recallsRoute } from "./recalls/route";
import { recycleBinRoute } from "./recycle-bin/route";
import { renewalsRoute } from "./renewals/route";
import { samplesRoute } from "./samples/route";
import { tobaccoRoute } from "./tobacco/route";

export const hmRoute: ICCRoute = {
  path: "hm",
  name: "Health Manager",
  enum: eProductType.HealthManager,
  children: [
    premisesRoute,
    tobaccoRoute,
    renewalsRoute,
    inspScheduleRoute,
    inspectionsRoute,
    complaintsRoute,
    noticesRoute,
    samplesRoute,
    recallsRoute,
    financeRoute,
    fsrRoute,
    govtReportsRoute,
    systemAdminRoute,
    recycleBinRoute,
    {
      path: "premises/:id(new|[0-9]+)",
      component: require("./premises/[id]/_index").default,
    },
  ],
};
