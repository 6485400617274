import { calculationMaxFactorPercentage } from "@app/products/property/assessments/components/custom-cell/association-factor-percentage-custom-cell/util";
import { DTO_Parcel } from "@app/products/property/components/dialogs/add-parcel-lookup/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { PERCENTAGE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { ICustomEditCell } from "@components/cc-grid/model";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import React from "react";
import "./_index.scss";
export const AssociationFactorPercentageCustomCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
}: ICustomEditCell) => {
  const [assessmentLabel] = Label.CommunityProperty.getLabel([
    ECustomColNameProperty.AssessmentLowercase,
  ]);
  const dataRow: DTO_Parcel = dataItem;
  const maxPercentage = calculationMaxFactorPercentage(dataRow);

  //auto reset Area Percentage need get maxPercentage can be allocated when it is null
  const calValuePercentage = (value: number | null) => {
    return isNil(value) || value <= 0 || value > maxPercentage
      ? maxPercentage
      : value;
  };
  return (
    <div className="cc-percentage-text-box-custom-cell">
      <CCNumericTextBox
        className="cc-percentage-text-box-custom"
        value={calValuePercentage(value)}
        format={PERCENTAGE_FORMAT.PERCENTAGE_NOT_DECIMAL}
        max={maxPercentage}
        onChange={(event: NumericTextBoxChangeEvent) => {
          if (onChange) {
            onChange({
              field: field,
              dataIndex: dataIndex || 0,
              dataItem,
              syntheticEvent: event.syntheticEvent,
              value: calValuePercentage(event.value),
            });
          }
        }}
      />
      {/* Tooltip provides max percentage user can pick */}
      <CCTooltip
        type="info"
        position="right"
        content={`Maximum percentage is ${maxPercentage}%. If the Parcel is already linked 100% to another ${assessmentLabel} the value will be defaulted back to 0%.`}
      />
    </div>
  );
};
