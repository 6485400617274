import { ProRataMethod, RenewalMethod } from "@app/products/hm/model";
import {
  getNameOfPremises,
  nameOfSvcPremises,
} from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { checkSecondaryMode } from "@app/products/hm/premises/[id]/config";
import { HM_PREMISES_HANDLER_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  IHMHandlerEventInitialData,
  PremiseHandlerRequest,
  Premises,
  PremisesRegisterLovs,
  PremisesUIControl,
  SecondaryModeCheckField,
  Svc_FormAction_Premise,
} from "@app/products/hm/premises/[id]/model";
import { getLastInspectionDisplay } from "@app/products/hm/premises/[id]/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import {
  HEALTHMANAGER_CONFIGURATIONMODE,
  IssueRegistrationFee,
  NUMBERINGFORMAT,
} from "@common/constants/enumerations";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import {
  requiredValidator,
  validatorDateValueWithMinMax,
} from "@common/utils/field-validators";
import {
  capitalizeFirstLetter,
  formatDateByKendo,
} from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { isHTML } from "@components/cc-input-picker/util";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";

export interface IHMPremisesDatesSectionProps {
  isInactive?: boolean;
  formRenderProps: FormRenderProps;
  allowEditRegistration?: boolean;
  allowOverrideInspFrequency?: boolean;
}
const nameOfIKeyValuePacket = nameOfFactory<IKeyValuePacket>();
export const DatesSection = observer(
  ({
    formRenderProps,
    isInactive = false,
    allowEditRegistration = true,
    allowOverrideInspFrequency = true,
  }: IHMPremisesDatesSectionProps) => {
    const { dataForms } = useFlexibleFormStore();
    const { settings } = useCommonCoreStore();
    const { valueGetter, onChange } = formRenderProps;

    const premisesObj = valueGetter(nameOfSvcPremises("Premises")) as Premises;
    const uiControlStore = dataForms?.GeneralUIControl as PremisesUIControl;
    const lovs = dataForms?.GeneralFormLovs as PremisesRegisterLovs;
    const initDataForm = dataForms?.GeneralForm?.Premises as Premises;

    const registrationToValue = valueGetter(
      getNameOfPremises("Date_RegistrationTo")
    );
    const registrationFromValue = valueGetter(
      getNameOfPremises("Date_RegistrationFrom")
    );

    const registrationToValidate = useCallback(
      (value: any) => {
        if (isMandatoryRegisteredFromTo) {
          return requiredValidator(value);
        }
        if (registrationToValue) {
          return validatorDateValueWithMinMax(
            value,
            registrationFromValue,
            null
          );
        }
        return undefined;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [registrationToValue, registrationFromValue]
    );
    const registrationFromValidate = useCallback(
      (value: any) => {
        if (isMandatoryRegisteredFromTo) {
          return requiredValidator(value);
        }
        if (registrationFromValue) {
          return validatorDateValueWithMinMax(value, null, registrationToValue);
        }
        return undefined;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [registrationFromValue, registrationToValue]
    );

    // #region GET VALUE FROM SETTINGS
    const configurationMode = getNumberValueSetting(
      settings[ECorporateSettingsField.HealthManager_ConfigurationMode]
    );
    const registrationNoLabelSetting = getStringValueSetting(
      settings[ECorporateSettingsField.HealthManager_RegistrationNoLabel]
    );
    const numberFormat = getNumberValueSetting(
      settings[ECorporateSettingsField.HealthManager_NumberFormat]
    );
    const issueRegistrationFee = getStringValueSetting(
      settings[ECorporateSettingsField.HealthManager_IssueRegistrationFee]
    );
    const isPreRegDtCheck = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_PreRegDtCheck]
    );
    const isBusinessActivity = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_BusinessActivity]
    );
    const enableSecondaryMode = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_EnableSecondaryMode]
    );
    const sFieldMapping =
      getStringValueSetting(
        settings[ECorporateSettingsField.HealthManager_TechOneFieldMapping]
      ) ?? "";

    // #endregion GET VALUE FROM SETTINGS

    // #region LABEL
    const registrationNoLabel = useMemo(() => {
      const labelValue = capitalizeFirstLetter(registrationNoLabelSetting);
      return labelValue && valueGetter(getNameOfPremises("RegistrationNumber"))
        ? labelValue
        : "Registration number";
    }, [
      valueGetter,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      valueGetter(getNameOfPremises("RegistrationNumber")),
      registrationNoLabelSetting,
    ]);
    // #endregion LABEL

    // #region SHOW/HIDE
    const isShowRegistrationDates = useMemo(() => {
      let isVisible = false;
      if (valueGetter(getNameOfPremises("PremisesType"))) {
        if (
          valueGetter(getNameOfPremises("PremisesType"))?.RenewalMethod_ENUM ===
          RenewalMethod.None
        ) {
          isVisible = false;
        } else {
          if (
            valueGetter(getNameOfPremises("PremisesType"))?.ProRata_ENUM &&
            valueGetter(getNameOfPremises("PremisesType"))?.ProRata_ENUM !==
              ProRataMethod.None &&
            issueRegistrationFee &&
            parseInt(issueRegistrationFee) === IssueRegistrationFee.Creation
          ) {
            isVisible = true;
          }
        }
      }

      if (
        initDataForm?.Date_RegistrationFrom ||
        initDataForm?.Date_RegistrationTo
      ) {
        isVisible = true;
      }
      if (numberFormat === NUMBERINGFORMAT.Manual) {
        isVisible = true;
      }
      return isVisible;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      valueGetter(getNameOfPremises("Date_RegistrationFrom")),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      valueGetter(getNameOfPremises("Date_RegistrationTo")),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      valueGetter(getNameOfPremises("PremisesType")),
      issueRegistrationFee,
      numberFormat,
      valueGetter,
    ]);
    // #endregion SHOW/HIDE

    // #region MANDATORY
    const isMandatoryRegisteredFromTo = useMemo(() => {
      if (
        valueGetter(getNameOfPremises("PremisesType")) &&
        !isNil(
          valueGetter(getNameOfPremises("PremisesType"))?.RenewalMethod_ENUM
        ) &&
        valueGetter(getNameOfPremises("PremisesType"))?.RenewalMethod_ENUM !==
          RenewalMethod.None
      ) {
        if (
          !isNil(
            valueGetter(getNameOfPremises("PremisesType"))?.ProRata_ENUM
          ) &&
          valueGetter(getNameOfPremises("PremisesType"))?.ProRata_ENUM !==
            ProRataMethod.None &&
          issueRegistrationFee &&
          parseInt(issueRegistrationFee) === IssueRegistrationFee.Creation &&
          isPreRegDtCheck
        ) {
          return true;
        }
      }
      return false;
    }, [
      valueGetter,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      valueGetter(getNameOfPremises("PremisesType")),
      isPreRegDtCheck,
      issueRegistrationFee,
    ]);
    // #endregion MANDATORY

    // #region Secondary Mode
    const checkSecondaryModeTxtRegistrationNo = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.TxtRegistrationNo
    );
    // #endregion Secondary Mode

    const litLastInspection = getLastInspectionDisplay(
      valueGetter(getNameOfPremises("LastInspection")),
      valueGetter(getNameOfPremises("LastInspectionResult"))
    );

    const handleChangeAuditFrequency = (event: ComboBoxChangeEvent) => {
      onChange(getNameOfPremises("AuditFrequency"), {
        value: event?.target?.value?.Key ?? null,
      });
    };

    const isShowDateSections =
      numberFormat === NUMBERINGFORMAT.Manual ||
      valueGetter(getNameOfPremises("PremisesType"));

    // #region Inspection Frequency
    const handleChangeInspectionFrequency = (event: ComboBoxChangeEvent) => {
      let premisesHandlerRequest: PremiseHandlerRequest = {
        FormAction: Svc_FormAction_Premise.Form_InspectionFrequencyChange,
        Premises: premisesObj,
        Args: event?.target?.value?.Key ?? "",
        IsFirstTimeLoad: false,
        UIControl: uiControlStore,
        PremiseLOVs: lovs,
      } as PremiseHandlerRequest;

      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: `Select inspection frequency failed.`,
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    // #endRegion Inspection Frequency

    return (
      <>
        {isShowDateSections && (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              {isShowRegistrationDates && (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel
                      title="Registered from"
                      isMandatory={isMandatoryRegisteredFromTo}
                    />
                    <Field
                      name={getNameOfPremises("Date_RegistrationFrom")}
                      format={DATE_FORMAT.DATE_CONTROL}
                      component={CCDatePicker}
                      validator={registrationFromValidate}
                      max={
                        registrationToValue
                          ? new Date(registrationToValue)
                          : undefined
                      }
                      disabled={isInactive || !allowEditRegistration}
                    />
                  </div>
                  {configurationMode !==
                    HEALTHMANAGER_CONFIGURATIONMODE.WATER_AUTHORITY && (
                    <div className="cc-field">
                      <CCLabel
                        title="Registered to"
                        isMandatory={isMandatoryRegisteredFromTo}
                      />
                      <Field
                        name={getNameOfPremises("Date_RegistrationTo")}
                        format={DATE_FORMAT.DATE_CONTROL}
                        component={CCDatePicker}
                        validator={registrationToValidate}
                        min={
                          registrationFromValue
                            ? new Date(registrationFromValue)
                            : undefined
                        }
                        disabled={isInactive || !allowEditRegistration}
                      />
                    </div>
                  )}
                  <div className="cc-field">
                    <CCLabel title={registrationNoLabel} />
                    <Field
                      name={getNameOfPremises("RegistrationNumber")}
                      component={CCInput}
                      placeholder={registrationNoLabel}
                      readOnly={
                        isInactive ||
                        checkSecondaryModeTxtRegistrationNo ||
                        !allowEditRegistration
                      }
                    />
                  </div>
                </div>
              )}
              {valueGetter(getNameOfPremises("PremisesType"))
                ?.Flag_ScheduledInspections && (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Inspection frequency" />
                    <Field
                      name={getNameOfPremises("InspectionFrequency")}
                      textField={nameOfIKeyValuePacket("Value")}
                      dataItemKey={nameOfIKeyValuePacket("Key")}
                      data={lovs?.InspectionFrequency ?? []}
                      value={getDropdownValue(
                        valueGetter(
                          `${getNameOfPremises("InspectionFrequency")}`
                        ),
                        lovs?.InspectionFrequency ?? [],
                        nameOfIKeyValuePacket("Key")
                      )}
                      onChange={handleChangeInspectionFrequency}
                      disabled={isInactive || !allowOverrideInspFrequency}
                      component={CCSearchComboBox}
                      allowCustom={true}
                      placeholder={"Select inspection frequency"}
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label="Last inspection"
                      value={
                        isHTML(litLastInspection)
                          ? sanitizeHtml(litLastInspection)
                          : litLastInspection
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label="Next inspection"
                      value={
                        valueGetter(getNameOfPremises("NextInspection"))
                          ? formatDateByKendo(
                              valueGetter(getNameOfPremises("NextInspection")),
                              DATE_FORMAT.DATE
                            )
                          : "Not yet inspected"
                      }
                    />
                  </div>
                </div>
              )}
              {valueGetter(getNameOfPremises("PremisesType"))?.Flag_Samples && (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCValueField
                      label="Last sampled"
                      value={
                        valueGetter(getNameOfPremises("LastSampled"))
                          ? formatDateByKendo(
                              valueGetter(getNameOfPremises("LastSampled")),
                              DATE_FORMAT.DATE
                            )
                          : "Not yet sampled"
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label="Details"
                      value={
                        valueGetter(getNameOfPremises("LastSampled"))
                          ? valueGetter(getNameOfPremises("LastSampledDetails"))
                          : "Not yet sampled"
                      }
                    />
                  </div>
                </div>
              )}
              {valueGetter(getNameOfPremises("PremisesType"))?.Flag_FSP && (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Audit frequency" />
                    <Field
                      name={getNameOfPremises("AuditFrequency")}
                      textField={nameOfIKeyValuePacket("Value")}
                      dataItemKey={nameOfIKeyValuePacket("Key")}
                      data={lovs?.AuditFrequency ?? []}
                      value={getDropdownValue(
                        valueGetter(`${getNameOfPremises("AuditFrequency")}`),
                        lovs?.InspectionFrequency ?? [],
                        nameOfIKeyValuePacket("Key")
                      )}
                      onChange={handleChangeAuditFrequency}
                      disabled={isInactive}
                      component={CCSearchComboBox}
                      allowCustom={true}
                      placeholder={"Select audit frequency"}
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label="Last audit"
                      value={
                        valueGetter(getNameOfPremises("LastAudit"))
                          ? formatDateByKendo(
                              valueGetter(getNameOfPremises("LastAudit")),
                              DATE_FORMAT.DATE
                            )
                          : "Not yet audited"
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label="Next audit"
                      value={
                        valueGetter(getNameOfPremises("NextAudit"))
                          ? formatDateByKendo(
                              valueGetter(getNameOfPremises("NextAudit")),
                              DATE_FORMAT.DATE
                            )
                          : "Not scheduled"
                      }
                    />
                  </div>
                </div>
              )}
            </section>
          </>
        )}
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            {isBusinessActivity && (
              <div className="cc-field">
                <CCLabel title="Previous category" />
                <Field
                  name={getNameOfPremises("PreviousCategory")}
                  component={CCInput}
                  placeholder="Previous category"
                  readOnly={isInactive}
                />
              </div>
            )}
            <div className="cc-field cc-col-span-2">
              <CCLabel title="Details" />
              <Field
                name={getNameOfPremises("Comments")}
                component={CCTextArea}
                placeholder="Details"
                rows={3}
                readOnly={isInactive}
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
